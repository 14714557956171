import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import { MdKeyboardArrowDown } from 'react-icons/md';
import api from '~/services/api';
import { AlertBox, DataList } from './styles';
import Select, { AsyncSelect } from '@atlaskit/select';

interface IFormData {
  text: string | any;
  option: { label: string; value: number | string } | any;
}

// type TipoPessoa = 'Cliente' | 'Fornecedor' | 'Loja';

interface Options {
  label: string | undefined;
  value: number | undefined | string;
}

interface Ncm {
  cod_ncm: number;
  descricao: string;
}

interface Select {
  label: string;
  value: number;
}

interface BuscaNcmProps {
  onChange(e: any): void;
  children?: ReactNode;
  refs?: any;
  placeholder?: string;
  label?: string;
  value: string | undefined;
  isUpdate: boolean;
  init: boolean;
  invalid?: boolean;
  required?: boolean;
  isDisabled?: boolean;
}
export const BuscaNcm: React.FC<BuscaNcmProps> = forwardRef((props) => {
  const {
    children,
    placeholder = 'Digite para buscar o Fornecedor',
    onChange,
    value = null,
    isUpdate,
    init,
    required = false,
    invalid = false,
    isDisabled = false,
  } = props;
  const inputRef = useRef<any>(null);

  const [store, setStore] = useState<Select[]>([]);
  const [storeAtivo, setStoreAtivo] = useState<any[]>([]);
  const [filtro, setFiltro] = useState<Select | null>(null);
  const [storeFiltered, setStoreFiltered] = useState<Select[]>([]);
  const [isInvalid, setIsInvalid] = useState(false);
  const [inicializado, setInicializado] = useState(false);

  useEffect(() => {
    setInicializado(init);
  }, [init]);

  useEffect(() => {
    setIsInvalid(invalid);
  }, [invalid]);

  const addAlert = useCallback((): string => {
    if (inicializado && isInvalid) {
      return 'alert-class';
    }
    return '';
  }, [inicializado, isInvalid]);

  useImperativeHandle(props.refs, () => ({
    handleClearNcm: () => handleClear(),
  }));

  const handleClear = () => {
    setFiltro(null);
  };

  useEffect(() => {
    if (value) {
      const hasExists = store.find(
        (reg) => Number(reg.value) === Number(value),
      );

      if (!hasExists) {
        return;
      }
      setFiltro({
        label: hasExists?.label,
        value: hasExists?.value,
      });
    }
  }, [value, store]);

  const handleChange = useCallback(
    (e: any) => {
      onChange(e);
      setFiltro(e);
    },
    [onChange],
  );

  const getNcms = async () => {
    const res = await api.get('/produto/ncms');
    const { data } = res.data;
    const optAtivo = data.flatMap((ncm: any) => {
      if (ncm.flg_inativo !== true) {
        return [
          {
            value: ncm.cod_ncm,
            label: `${ncm.cod_ncm} - ${ncm.descricao}`,
            cod_tipo_item: ncm.cod_tipo_item,
          },
        ];
      }
      return [];
    });

    setStoreAtivo(optAtivo);

    const options = data.map((ncm: Ncm) => {
      return {
        value: ncm.cod_ncm,
        label: `${ncm.cod_ncm} - ${ncm.descricao}`,
      };
    });

    setStore(options);
    setStoreFiltered([]);
  };

  useEffect(() => {
    getNcms();
  }, []);

  const handleFilter = (e: string) => {
    if (isUpdate && e !== value) {
      if (e.length < 3) return setStoreFiltered([]);
    }
    if (!isUpdate && (e === '' || e.length < 3)) {
      return setStoreFiltered([]);
    }
    const filtered = storeAtivo.filter(
      (data) => data.label.indexOf(e.toUpperCase()) > -1,
    );
    setStoreFiltered(filtered);
  };

  const customStyles = {
    control: (styles: any) => {
      return {
        ...styles,
        color: 'green',
        backgroundColor: isDisabled
          ? 'var(--ds-background-disabled,#FAFBFC)'
          : 'var(--ds-background-subtleBorderedNeutral-resting,#FAFBFC)',
        borderColor: isDisabled
          ? 'var(--ds-background-disabled,#FAFBFC)'
          : 'var(--ds-border-neutral,#DFE1E6)',
      };
    },
    singleValue: (provided: any, state: any) => {
      return {
        ...provided,
        // color: 'green',
        // color: 'var(--ds-text-lowEmphasis,#7A869A)',
        // color: filtro ? '#172B4D' : 'var(--ds-text-lowEmphasis,#7A869A)',
      };
    },
  };

  return (
    <>
      <AlertBox>
        <div className={addAlert()}>
          <Field name="filtro" defaultValue="" label="NCM *">
            {() => (
              <>
                <Select
                  options={storeFiltered}
                  ref={inputRef}
                  className="async-select-with-callback"
                  classNamePrefix="react-select"
                  placeholder={placeholder}
                  onChange={handleChange}
                  onInputChange={handleFilter}
                  isDisabled={isDisabled}
                  // onMenuOpen={() => setFiltro(null)}
                  value={filtro}
                  styles={customStyles}
                  noOptionsMessage={() => 'Nenhum registro encontrado'}
                />
              </>
            )}
          </Field>
        </div>
      </AlertBox>
    </>
  );
});
