import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import React from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { BiRefresh, BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useProduto } from '~/pages/Produto/ProdutoContext';
import {
  FormEmbalagem,
  FormGtin,
  formGtinBlank,
  TransferenciaGtinProps,
} from '~/pages/Produto/protocols';
import { Icon, InputIconContainer } from '~/pages/Produto/styles';
import api from '~/services/api';
import { validaGtin } from '~/utils/functions';

import { List, ListItem } from './styles';

interface GtinProps {
  onShowModalChanged: any;
  onGtinModalChanged: any;
  tipoGtin: number;
  setTipoGtin: (tipoGtin: number) => void;
}

export const Gtin: React.FC<GtinProps> = ({
  onShowModalChanged,
  onGtinModalChanged,
  tipoGtin,
  setTipoGtin,
}) => {
  const MySwal = withReactContent(Swal);

  const {
    formProduto,
    changeFormProduto,
    isUpdate,
    setFormGtin,
    formGtin,
    selectedLoja,
  } = useProduto();

  function handleChangeTipoGtin() {
    switch (tipoGtin) {
      case 8:
        return setTipoGtin(12);
      case 12:
        return setTipoGtin(13);
      case 13:
        return setTipoGtin(14);
      default:
        return setTipoGtin(8);
    }
  }

  async function verificaGtin(cod_gtin: string) {
    try {
      const res = await api.post('/produto/validar/gtin', { cod_gtin });
      const { success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      return false;
    } catch (error: any) {
      toast.warning(error.message);
      return true;
    }
  }

  function handleClearFormGtin() {
    setFormGtin(formGtinBlank);
  }

  const handleGetGtin = (item: FormGtin) =>
    item?.cod_gtin?.value ?? formProduto.cod_gtin_principal.value;

  async function handleAddGtin(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      if (
        formGtin.cod_gtin.value === '' ||
        Number(formGtin.cod_gtin.value) === 0
      ) {
        return toast.warning('GTIN não informado.');
      }

      const formatedGtin =
        Array(tipoGtin - (formGtin.cod_gtin.value.length - 1)).join('0') +
        formGtin.cod_gtin.value;

      const hasInserted = formProduto.Gtins.gtins.find((item) => {
        return Number(handleGetGtin(item)) === Number(formatedGtin);
      });

      if (hasInserted) {
        return toast.warning('Gtin Já Inserido no Produto!');
      }

      const hasValid = validaGtin(formatedGtin, tipoGtin);

      if (!hasValid.valid) {
        return toast.warning(hasValid.message);
      }

      if (hasValid.valid) {
        const hasExists = await verificaGtin(formatedGtin);

        if (hasExists) {
          return;
        }

        formGtin.cod_gtin.value = formatedGtin;
        formGtin.cod_gtin.isNew = true;

        if (formProduto.Gtins.gtins.length === 0) {
          changeFormProduto({
            ...formProduto,
            cod_gtin_principal: {
              ...formProduto.cod_gtin_principal,
              value: formatedGtin,
            },
            Gtins: {
              ...formProduto.Gtins,
              gtins: [...formProduto.Gtins.gtins, formGtin],
            },
          });
        } else {
          changeFormProduto({
            ...formProduto,
            Gtins: {
              ...formProduto.Gtins,
              gtins: [...formProduto.Gtins.gtins, formGtin],
            },
          });
        }
        handleClearFormGtin();
      }
    }
  }

  async function handleChangeGtinPrincipal(item: FormGtin) {
    formProduto.Gtins.gtins.map((i) => {
      if (Number(i.cod_gtin.value) === Number(handleGetGtin(item))) {
        const newEmbalagens = formProduto.Embalagens.embalagens.map(
          (emb: FormEmbalagem) => {
            return {
              ...emb,
              flg_principal: {
                ...emb.flg_principal,
                value: false,
              },
            };
          },
        );

        changeFormProduto({
          ...formProduto,
          cod_gtin_principal: {
            ...formProduto.cod_gtin_principal,
            value: i.cod_gtin.value,
          },
          Embalagens: {
            ...formProduto.Embalagens,
            embalagens: newEmbalagens,
          },
        });
        sortGtin(i.cod_gtin.value);
      }

      return i;
    });
  }

  function sortGtin(value: any) {
    const getinArr = formProduto.Gtins.gtins;

    const gtinIndex = getinArr.findIndex(
      (gtin) => gtin.cod_gtin.value === value,
    );
    const gtinFind = getinArr.find((gtin) => gtin.cod_gtin.value === value);

    getinArr.splice(gtinIndex, 1);
    if (gtinFind) {
      getinArr.unshift(gtinFind);
    }

    changeFormProduto({
      ...formProduto,
      cod_gtin_principal: {
        ...formProduto.cod_gtin_principal,
        value,
      },
      Gtins: {
        ...formProduto.Gtins,
        gtins: getinArr,
      },
    });
  }

  async function handleConfirmChangeGtinPrincipal(item: FormGtin) {
    if (isUpdate) {
      MySwal.fire({
        title: 'Alteração no GTIN Principal',
        text: `Alterar o GTIN principal implica em mudança na embalagem principal em todas as lojas que o produto está cadastrado. Deseja continuar?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        buttonsStyling: true,
      }).then((result) => {
        if (result.isConfirmed) {
          handleChangeGtinPrincipal(item);
        }
      });
    } else {
      handleChangeGtinPrincipal(item);
    }
  }

  async function handleRemoveGtin(item: FormGtin) {
    try {
      if (isUpdate && !item.cod_gtin?.isNew) {
        if (handleGetGtin(item) === formProduto.cod_gtin_principal.value) {
          throw new Error('Não é permitido excluir o GTIN principal.');
        }
        MySwal.fire({
          title: 'Inativar GTIN',
          text: `INATIVAR o GTIN implica em inativar todas embalagens relacionadas a ele em todas lojas. Deseja continuar?`,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          reverseButtons: true,
          buttonsStyling: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const filteredGtins = formProduto.Gtins.gtins.filter(
              (i: FormGtin) => {
                return i.cod_gtin.value !== handleGetGtin(item);
              },
            );
            const filteredEmbalagens = formProduto.Embalagens.embalagens.filter(
              (i: FormEmbalagem) => {
                return i.cod_gtin.value.value !== handleGetGtin(item);
              },
            );
            changeFormProduto({
              ...formProduto,
              Gtins: {
                ...formProduto.Gtins,
                gtins: filteredGtins,
              },
              Embalagens: {
                ...formProduto.Embalagens,
                embalagens: filteredEmbalagens,
              },
            });
            const res = await api.post(`/produto/gtin/inativar`, {
              cod_gtin: handleGetGtin(item),
            });
            const { success, message } = res.data;
            if (!success) {
              throw new Error(message);
            }
            return toast.success(message);
          }
        });
      } else {
        const filteredGtins = formProduto.Gtins.gtins.filter((i: FormGtin) => {
          return i.cod_gtin.value !== handleGetGtin(item);
        });

        const filteredEmbalagens = formProduto.Embalagens.embalagens.filter(
          (i: FormEmbalagem) => {
            return i.cod_gtin.value.value !== handleGetGtin(item);
          },
        );
        changeFormProduto({
          ...formProduto,
          Gtins: {
            ...formProduto.Gtins,
            gtins: filteredGtins,
          },
          Embalagens: {
            ...formProduto.Embalagens,
            embalagens: filteredEmbalagens,
          },
        });
      }
    } catch (error: any) {
      toast.dark(error.message);
    }
  }

  async function handleTransferGtin(item: FormGtin) {
    try {
      if (isUpdate) {
        const gtinItem = handleGetGtin(item);

        if (gtinItem === formProduto.cod_gtin_principal.value) {
          throw new Error('Não é permitido transferir o GTIN principal.');
        }

        if (formProduto.cod_produto.value && selectedLoja) {
          const { value: produtoId } = formProduto.cod_produto;

          const { data } = await api.get<TransferenciaGtinProps>(
            `/produto/transferencia-gtin/${produtoId}/${selectedLoja}`,
          );

          if (!data.success) return;

          const gtinEncontrado = data.data.find(
            (gtin) => gtin.cod_gtin === gtinItem,
          );

          if (!gtinEncontrado) {
            onShowModalChanged(true);
            onGtinModalChanged(gtinItem);
            return;
          }

          if (!gtinEncontrado.flg_carga_pdv) {
            onShowModalChanged(false);
            toast.warning(
              'GTIN não pode ser transferido, pois o produto está pendente de carga. Regularize a situação para prosseguir.',
            );
            return;
          }
        }

        onShowModalChanged(true);
        onGtinModalChanged(gtinItem);
      }
    } catch (error: any) {
      toast.dark(error.message);
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <Field name="cod_gtin" label="GTIN *">
        {({ fieldProps }) => (
          <InputIconContainer>
            <Textfield
              {...fieldProps}
              maxLength={tipoGtin}
              type="text"
              id="gtin"
              value={formGtin.cod_gtin.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!Number.isNaN(Number(e.target.value))) {
                  setFormGtin({
                    ...formGtin,
                    cod_gtin: {
                      ...formGtin.cod_gtin,
                      value: /^[0-9]+/.test(e.target.value)
                        ? e.target.value
                        : '',
                    },
                  });
                }
              }}
              onKeyPress={handleAddGtin}
              style={{
                padding: '8px 36px 8px 6px',
              }}
            />
            <Icon
              className="icon icon-right"
              onClick={handleChangeTipoGtin}
              style={{
                color: 'white',
                height: '40px',
                width: '40px',
                backgroundColor: '#8850BF',
              }}
            >
              {tipoGtin}
            </Icon>
          </InputIconContainer>
        )}
      </Field>

      <div className="small">Pressione Enter para Confirmar</div>

      <List>
        {formProduto.Gtins.gtins.map((item) => (
          <ListItem
            maingtin={
              formProduto.cod_gtin_principal.value === handleGetGtin(item)
            }
            title={
              formProduto?.cod_gtin_principal.value === handleGetGtin(item)
                ? 'Gtin Principal'
                : ''
            }
            key={handleGetGtin(item)}
          >
            {formProduto.cod_gtin_principal.value === handleGetGtin(item) ? (
              <AiFillStar className="icon" />
            ) : (
              <AiOutlineStar
                className="icon"
                title="Definir como principal?"
                onClick={() => handleConfirmChangeGtinPrincipal(item)}
              />
            )}
            {handleGetGtin(item)}

            {formProduto.cod_gtin_principal.value !== handleGetGtin(item) && (
              <div className="options">
                {isUpdate && (
                  <BiRefresh
                    title="Transferir GTIN"
                    onClick={() => handleTransferGtin(item)}
                  />
                )}
                <BiTrash
                  title="Excluir GTIN"
                  onClick={() => handleRemoveGtin(item)}
                />
              </div>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
};
